<template>
  <div>Response from cognito</div>
</template>
<script>
export default {
  name: "UserInfo",
  props: {},
  created() {
    this.getAuthparameters()
  },
  methods: {
    async getAuthparameters() {
    }
  }
}
</script>